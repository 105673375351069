<template>
	<navBar />
	<div class="bg-white"> 
        <Loader v-if="loading"/>
        <div class="responsive-chats" >
            <div class="mt-5 pt-2 mb-1">
                <div class="p-2 chat-header justify-content-between between" style="border-bottom: 1px solid #DCDEE2;">
                    <div class="d-flex" @click="gotoProfile()">
                        <div>
                            <img v-if="!get_data.recipient_dp" 
                                height="50" width="50"
                                class="raidus-100"
                                src="@/assets/images/profiles/default.png" >
                            <img v-if="get_data.recipient_dp" 
                                height="50" width="50"
                                class="raidus-100"
                                :src="`${image_api}/${get_data.recipient_dp}`">
                            <!-- <img
                                    height="50" width="50"
                                    class="raidus-100"
                                    src="@/assets/images/profiles/default.png" > -->
                                
                        </div>
                        <div class="px-2">
                            <h6 class="fw-bold mb-0 mt-1">{{this.$route.query.name}}</h6>
                            <span class="m-right text-theme text-10">Active</span>
                        </div>
                    </div>
                    <div>
                        <i class="bx bxs-trash fs-3 text-danger me-5" @click="delete_modal = true"></i>
                        <i class="bx bx-arrow-back fs-3 text-info" @click="goBack()"></i>
                    </div>
                </div>
                <div class="messages-responsive">
                    <!-- <div class="p-1" v-for="data,i in this.get_data.messages" :key="i">
                        <span class="message-bubble" :class="data.from == this.profile.username ? 'chat-blue':'chat-grey'">{{data.text}}</span>
                    </div> -->
                    <div class="p-1 " v-for="data,i in this.get_data.messages" :key="i">
                        <span class="message-bubble" :class="data.from == this.profile.username ? 'chat-blue':'chat-grey'" v-if="data.text">{{data.text}}</span>
                        <div :class="data.from == this.profile.username ? 'chat-image-right':'chat-image-left'"  v-if="data.media">
                            <div v-if="parsedMedia(data.media).length > 0" >
                                <div v-for="(image, index) in parsedMedia(data.media)" :key="index" > 
                                    <img @click="postView(image)" v-if="isImageFile(image)" :src="getMediaUrl(image)" class="cursor-pointer" height="100" width="100" 
                                        style="border-radius:8px;"/>
                                    <video
                                        v-if="isVideoFile(image)"
                                        :src="getMediaUrl(image)"
                                        controls
                                        height="100"
                                        width="100"
                                        style="border-radius:8px;"
                                    ></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-message-responsive">
                <div class="p-2">
                    <i @click="image_upload_modal = true" class="bx bx-image text-success" style="left: 18px !important; width:50px;"></i>
                    <input ref="commentInput" placeholder="Write something.."  maxlength="50" @keyup.enter="replyChat($event,false)"/>
                    <i class="bx bx-send send-icon" @click="replyChatButton()"></i>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        
        <!-- MODALS -->
        <b-modal 
            v-model="image_upload_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Upload Image" 
            centered
            >
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelect" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1" variant="info" @click="replyChatButton(true),image_upload_modal = false">Save</b-button>
            </div>
        </b-modal>
        <b-modal 
            v-model="post_view_modal" 
            hide-footer
            size="md"
            title="View" 
            centered
            hide-header
            >
            <img class="post-view-image image-pointer" :src="`${image_api}/${this.post_view_media}`"/>
        </b-modal>
        <b-modal 
            v-model="delete_modal" 
            hide-footer
            size="md"
            title="Are you sure you want to delete this?" 
            centered
            >
            <div>
                <label>Enter your username</label>
                <input class="form-control mb-3" placeholder="ex:(username01)" v-model="pl_delete.username"/>
            </div>
            <div class="text-end">
                <button class="btn btn-danger btn-sm" @click="deleteConversation()">Delete</button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import navBar from "@/components/nav-bar.vue";
import '@/assets/css/user-dashboard.css';
import { mapActions,mapGetters} from "vuex";
import formatter from '@/mixins/formatter';
import Echo from "laravel-echo";
import Swal from 'sweetalert2';
import Loader from '@/components/widgets/loader.vue';
export default {
    data() {
        return {
            get_data: [],
            image_api: process.env.VUE_APP_BACKEND_API_IMAGES,
            media_files: "Media/Files",
            chat_toggle:false,
            newMessage: false,
            pl:{
                text: "",
            },
            image_upload_modal: false,
            post_view_modal: false,
            selectedMedia: [],
            pl_delete:{
                username: '',
            },
            loading: false,
            delete_modal:false,
        };
    },
    mixins:[formatter],
	components: {
		navBar,Loader
	},
	computed: {
        ...mapGetters('auth', ['profile']),
	},
    methods:{
		...mapActions("messages", {
			getThreads: "getList",
			storeThread: "newThread",
			messageUser: "message",
			showUserThread: "showThread",
			updateThreads: "updateThread",
			removeThread: "deleteThread",
		}),
        scrollToElement(options) {
            console.log("scroll");
            const el = document.getElementById("scroll-to");
            if (el) {
                el.scrollIntoView(options);
            }
        },
        // PARSE
        parsedMedia(media) {
            try {
                return JSON.parse(media);
            } catch (error) {
                return [];
            }
        },
        getMediaUrl(filename) {
            return `${this.image_api}/${filename}`;
        },
        isImageFile(image) {
            return /\.(jpg|jpeg|png|gif)$/i.test(image);
        },
        isVideoFile(image) {
            return /\.(mp4|webm|ogg)$/i.test(image);
        },
        async initMessagesList(){
            var pl ={
                id: this.$route.query.thread_id,
                sort: 'created_at',
                order: 'desc',
                limit: 50
            }
			const data = await this.showUserThread(pl);
            this.get_data = data;
            this.webSocket();
        },
        async replyChat(event,modal){
            const fd = new FormData();
            fd.append('thread_id', this.get_data._id);
            fd.append('receiver_id', this.get_data._receiver_id);
            if(modal == true){
                if(this.selectedMedia){
                    this.selectedMedia.forEach((file, index) => {
                        fd.append(`media[${index}]`, file);
                    });
                }
            }else{
                if(event.target.value){
                    fd.append('text', event.target.value);
                }
                if(this.selectedMedia){
                    this.selectedMedia.forEach((file, index) => {
                        fd.append(`media[${index}]`, file);
                    });
                }
            }
            this.loading = true;
            const res = await this.messageUser(fd);
            this.loading = false;
            if(res.status == 200 || res.status == 'success'){
                console.log(res)
                this.selectedMedia = []
                event.target.value = ''
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
        replyChatButton(modal) {
            const commentInput = this.$refs.commentInput;
            const event = { target: commentInput };
            this.replyChat(event,modal);
        },
        postView(data){
            this.post_view_modal = true;
            this.post_view_media = data;
        },
        goBack(){
            this.$router.push({path:"/messages"});
        },
        handleFileSelect(event) {
            this.selectedMedia = Array.from(event.target.files);
        },
        gotoProfile(){  
            if(this.profile.id == this.get_data._receiver_id){
                this.$router.push({path: "/profile"});
            }else if(this.profile.id != this.get_data._receiver_id){
                this.$router.push({
                    path:"/profile",
                    query: {
                        user_id: this.get_data._receiver_id,
                        name: this.get_data.owner,
                    }
                })
            }
        },
        async deleteConversation() {
			var pl = {
                id: this.$route.query.thread_id
			}
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this conversation?',
                html: ``,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                if(this.profile.username.toLowerCase() == this.pl_delete.username.toLowerCase()){
                    const res = await this.removeThread(pl);
                    if (res.status == 200 || res.status == 'success') {
                        Swal.fire({
                            title: "Success",
                            html: `Conversation deleted.`,
                            type: "success",
                            icon: "success",
                            padding: "2em",
                        });
                        this.delete_modal = false;
                        this.goBack();
                    }else{
                        Swal.fire({
                            html: res,
                            type: "error",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                }else{
                    Swal.fire({
                        html: 'Make sure username text field is correct',
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
		},
        async updateMessage() {
			var pl = {
				status: "unread",
                id: this.get_data._id
			}
			await this.updateThreads(pl);
		},
        webSocket() {
			window.Pusher = require("pusher-js");
			window.Echo = new Echo({
				broadcaster: "pusher",
				key: process.env.VUE_APP_PUSHER_APP_KEY,
				// wsHost: process.env.VUE_APP_PUSHER_HOST,
				wsPort: process.env.VUE_APP_PUSHER_PORT,
				disableStats: true,
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				enabledTransports: ["ws", "wss"],
			});
			window.Echo.channel(`new-message.${this.get_data._id}`).listen(
				"NewMessageEvent",
				async (e) => {
					var pl = {
						text: e.text,
						media: e.media,
						from: e.from,
                        _thread_id: e.thread_id,
                        _receiver_id: e.receiver_id
					};
					await this.get_data.messages.push(pl);
                    this.updateMessage(e)
				}
			);
			// window.Echo.channel(`new-thread.${this.get_data._id}`).listen(
			// 	"NewThreadEvent",
			// 	async (e) => {
            //         if(e){
            //         }
			// 	}
			// );
		},
    },
    mounted(){
        this.initMessagesList(1);
    }
};
</script>